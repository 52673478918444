(function () {

    const hero = document.querySelector('.hero');



    if (hero) {
        const header = document.querySelector('.site-header')
        const heroImage = hero.querySelector('.hero-image')
        const picture = heroImage.querySelector('picture');
        const image = picture.querySelector('picture');
        hero.style.height = (window.innerHeight - header.offsetHeight) + 'px'
        hero.style.marginTop = header.offsetHeight + 'px'

        setTimeout(() => {
            heroImage.style.height = '50%'
            picture.classList.add('active')
        }, 1000);
    }





})();