import Rellax from 'Rellax';
import Glide from '@glidejs/glide';
import GLightbox from 'glightbox';



(function () {
    require('./modules/toolbox.js')

    require('./components/burger.js')

    require('./modules/splittex.js')
    require('./modules/textanimated.js')
    require('./modules/imageanimated.js')
    require('./components/text-image-with-background.js')
    require('./components/buttons.js')
    require('./components/hero.js')
    require('./components/carousel.js')
    window.addEventListener('beforeunload', function () {
        window.scrollTo(0, 0);
    })

    /* ------------------------------
    *  Utility
    * ------------------------------*/
    // Check Device 
    function isDesktop() {
        if (window.innerWidth >= 992) {
            return true;
        } else {
            return false;
        }
    };

    function isMobile() {
        if (window.innerWidth <= 767) {
            return true;
        } else {
            return false;
        }
    };

    function isTablet() {
        if (window.innerWidth >= 768 && window.innerWidth <= 991) {
            return true;
        } else {
            return false;
        }
    };



    function isTouch() {
        return ('ontouchstart' in window) || ('onmsgesturechange' in window);
    }

    function inWindow(element) {

        if (window.pageYOffset > (element.offsetTop - window.innerHeight) && window.pageYOffset < (element.offsetTop + element.offsetHeight)) {
            return true
        } else {
            return false;
        }

    }

    // Colophon
    colophon();
    function colophon() {
        console.log("%c Designed and Developed with ♥ by Antonio Marzullo | God is in the details ", "background: #0F0D24; color: #FFF; padding:3px 6px;font-family:'Cutive mono';");
    }

    // Site Loader
    hideSiteLoader();
    function hideSiteLoader() {
        let siteLoader = document.querySelector('.site-loader');
        let siteLoarderMessage = document.querySelector('.site-loader__message');
        let image = document.querySelectorAll('.slide-image');
        let square = document.querySelector('.slider-square');
        let slide = document.querySelectorAll('.slide-info-item');

        if (siteLoarderMessage) {
            siteLoarderMessage.addEventListener('animationend', () => {
                siteLoader.classList.add('site-loader--hidden');
                image[0].classList.add('current');
                setTimeout(function () {
                    square.classList.remove('slider-square--hidden');
                    setTimeout(function () {
                        slide[0].classList.remove('slide-info-item--hidden');
                        slide[0].classList.remove('slide-info-item--next')
                    }, 500);
                }, 1000);
            })
        }

    }

    // Header Interactions - Open Menu / Choose language
    headerInteractions();
    function headerInteractions() {
        let header = document.querySelector('.js-header');
        let menuTrigger = document.querySelector('.js-menu-trigger');
        let transitionPanel = document.querySelector('.js-transition-panel');
        let menu = document.querySelector('.js-menu');
        let chooseLangTrigger = document.querySelector('.js-choose-lang-trigger');
        let lang = document.querySelector('.js-lang');
        let currentLang = document.querySelector('.js-current-lang');
        let body = document.getElementsByTagName('body');

        // menuTrigger.addEventListener('click', function () {



        //     this.classList.toggle('menu-trigger--is-active');

        //     transitionPanel.classList.add('transition-panel--is-running');
        //     setTimeout(function () {

        //         menu.classList.toggle('menu--is-active');

        //         if (!lang.classList.contains('lang--is-active')) {
        //             // header.classList.toggle('header--is-dark');
        //             body[0].classList.toggle('is-menu-open');
        //         }

        //         if (lang.classList.contains('lang--is-active') && chooseLangTrigger.classList.contains('choose-lang-trigger--is-active')) {
        //             lang.classList.remove('lang--is-active');
        //             chooseLangTrigger.classList.remove('choose-lang-trigger--is-active')
        //             currentLang.classList.remove('current-lang--hidden');

        //         }

        //     }, 800);

        //     transitionPanel.addEventListener('animationend', function () {

        //         this.classList.remove('transition-panel--is-running');

        //     });

        // });

        // chooseLangTrigger.addEventListener('click', function () {



        //     transitionPanel.classList.add('transition-panel--is-running');

        //     setTimeout(function () {

        //         lang.classList.toggle('lang--is-active');
        //         currentLang.classList.toggle('current-lang--hidden');
        //         chooseLangTrigger.classList.toggle('choose-lang-trigger--is-active');
        //         if (!menu.classList.contains('menu--is-active')) {
        //             // header.classList.toggle('header--is-dark');
        //             body[0].classList.toggle('is-menu-open');
        //         }

        //         if (menu.classList.contains('menu--is-active') && menuTrigger.classList.contains('menu-trigger--is-active')) {
        //             menu.classList.remove('menu--is-active');
        //             menuTrigger.classList.remove('menu-trigger--is-active')
        //         }

        //     }, 800);

        //     transitionPanel.addEventListener('animationend', function () {

        //         this.classList.remove('transition-panel--is-running');

        //     });
        // });

    }


    // Header states on page scroll
    // headerOnScroll()
    function headerOnScroll() {
        let header = document.querySelector('.js-header');
        let lastScrollTop = 0;
        let menu = document.querySelector('.js-menu');
        let lang = document.querySelector('.js-lang');
        let checkBtn = document.querySelector('.check-avaibility-site');
        let footer = document.querySelector('.footer');



        window.addEventListener("scroll", function () {
            if (inWindow(footer)) {
                checkBtn.classList.add('check-avaibility-site--hidden');
            }
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (menu.classList.contains('menu--is-active') || lang.classList.contains('lang--is-active')) {
                return;
            } else {
                if (scrollTop > lastScrollTop) {
                    if (scrollTop > 200) {
                        header.classList.add('header--sticky');
                        header.classList.add('header--is-hidden');
                        if (isMobile() && !inWindow(footer)) {



                            checkBtn.classList.remove('check-avaibility-site--hidden');
                        }

                    }

                } else {
                    if (scrollTop < 200) {
                        header.classList.remove('header--sticky');
                        header.classList.remove('header--is-hidden');
                    }
                    header.classList.remove('header--is-hidden');
                    if (isMobile) {
                        checkBtn.classList.add('check-avaibility-site--hidden');
                    }
                }

                lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
            }



        }, false);



    }


    // Hero SLider
    heroSlider();
    function heroSlider() {
        let siteLoader = document.querySelector('.site-loader');
        let heroSlider = document.querySelector('.hero-slider');
        let filter = document.querySelector('.js-slide-filter');
        let image = document.querySelectorAll('.slide-image');
        let slide = document.querySelectorAll('.slide-info-item');
        let square = document.querySelector('.slider-square');
        let prev = document.querySelector('.js-slider-control--prev');
        let next = document.querySelector('.js-slider-control--next');
        var n = 0;
        if (heroSlider) {
            if (!siteLoader) {
                image[0].classList.add('current');
                setTimeout(function () {
                    square.classList.remove('slider-square--hidden');
                    setTimeout(function () {
                        slide[0].classList.remove('slide-info-item--hidden');
                        slide[0].classList.remove('slide-info-item--next')
                    }, 500);
                }, 500);

            }

            heroSlider.style.height = window.innerHeight + 'px';

            if (isDesktop) {
                window.addEventListener('resize', function () {
                    heroSlider.style.height = window.innerHeight + 'px';
                })
            }

            if (isTouch() && window.innerWidth >= 1024) {
                document.querySelector('.slider-controls').classList.add('slider-controls--touch');
            }

            next.addEventListener('click', function () {
                console.log('inizio n = ' + n);
                if (n < image.length - 1) {
                    prev.classList.remove('slider-control--deactive');
                    console.log('livello 1 N : ' + n);

                    filter.classList.add('transition');
                    square.classList.add('slider-square--hidden');
                    next.style.pointerEvents = 'none';
                    prev.style.pointerEvents = 'none';



                    slide[n].classList.add('slide-info-item--hidden');
                    slide[n].classList.add('slide-info-item--prev');

                    setTimeout(function () {
                        console.log('livello 2 N : ' + n);
                        image[n].classList.remove('current');
                        image[n + 1].classList.add('current');
                        filter.classList.remove('transition');


                        setTimeout(function () {
                            console.log('livello 3 N : ' + n);
                            slide[n + 1].classList.remove('slide-info-item--hidden');
                            slide[n + 1].classList.remove('slide-info-item--next');

                        }, 500);

                        setTimeout(function () {
                            console.log('livello 4 N : ' + n);
                            square.classList.remove('slider-square--hidden');

                        }, 500);

                    }, 1000);

                    setTimeout(() => {

                        n++
                        next.style.pointerEvents = 'auto';
                        prev.style.pointerEvents = 'auto';

                    }, 2000);
                    console.log('test n = ' + n);

                }
                if (n == image.length - 2) {
                    next.classList.add('slider-control--deactive');

                }



            });



            prev.addEventListener('click', function () {

                console.log('inizio n = ' + n);
                if (n > 0) {
                    next.classList.remove('slider-control--deactive');
                    console.log('livello 1 N : ' + n);

                    filter.classList.add('transition');
                    square.classList.add('slider-square--hidden');
                    prev.style.pointerEvents = 'none';
                    next.style.pointerEvents = 'none';

                    slide[n].classList.add('slide-info-item--hidden');
                    slide[n].classList.add('slide-info-item--next');

                    setTimeout(function () {
                        console.log('livello 2 N : ' + n);
                        image[n].classList.remove('current');
                        image[n - 1].classList.add('current');
                        filter.classList.remove('transition');


                        setTimeout(function () {
                            console.log('livello 3 N : ' + n);
                            slide[n - 1].classList.remove('slide-info-item--hidden');
                            slide[n - 1].classList.remove('slide-info-item--prev');

                        }, 500);

                        setTimeout(function () {
                            console.log('livello 4 N : ' + n);
                            square.classList.remove('slider-square--hidden');

                        }, 500);

                    }, 1000);

                    setTimeout(() => {

                        n--
                        prev.style.pointerEvents = 'auto';
                        next.style.pointerEvents = 'auto';

                    }, 2000);
                    // console.log('test n = ' + n);

                }
                if (n == 1) {
                    prev.classList.add('slider-control--deactive');

                }

            });
        }


    }

    // Show square on page scroll
    showImageOnScroll();
    function showImageOnScroll() {

        let image = document.querySelectorAll('.image');


        window.addEventListener('scroll', function (e) {

            for (let i = 0; i < image.length; i++) {

                if (inWindow(image[i])) {

                    if (image[i].dataset.delay) {
                        setTimeout(function () {
                            image[i].classList.add('image--showed');
                        }, image[i].dataset.delay * 1000);

                    } else {

                        image[i].classList.add('image--showed');
                    }





                }
            }

        })

    }



    // Split Text Animation
    // splitTitleAnimation();
    // function splitTitleAnimation() {
    //     let title = document.querySelectorAll('.js-split-title');

    //     for (let i = 0; i < title.length; i++) {
    //         let titleText = title[i].innerText;
    //         let titleSplited = titleText.split("");

    //         title[i].innerText = '';

    //         for (let x = 0; x < titleSplited.length; x++) {
    //             let el = document.createElement('span');
    //             el.innerText = titleSplited[x];

    //             if (titleSplited[i] === ' ') {
    //                 el.classList.add('space');

    //             } else {
    //                 el.classList.add('letter');
    //                 el.style.transitionDelay = '0.' + Math.floor(Math.random() * 10) + 's';
    //             }

    //             title[i].appendChild(el);
    //         }
    //     }

    // }

    showElementOnScroll();
    function showElementOnScroll() {
        let el = document.querySelectorAll('[data-scroll="fade-up"]');

        for (let i = 0; i < el.length; i++) {

            if (el[i].dataset.delay) {
                el[i].style.transitionDelay = el[i].dataset.delay + 's';
            }
            if (inWindow(el[i])) {

                el[i].dataset.show = true;

            }
            window.addEventListener('scroll', function (e) {
                if (inWindow(el[i])) {

                    el[i].dataset.show = true;

                }
            })


        }

    }


    // Parallax effect
    initRellax();
    function initRellax() {

        let rellax = new Rellax('.rellax', {
            center: true
        });
    }


    // Glide Carousers
    glideCarousers();
    function glideCarousers() {
        let offerCarousel = document.querySelector('.offers-slider');
        let gallerySlider = document.querySelector('.gallery-slider');

        if (offerCarousel) {
            new Glide(offerCarousel, {
                type: 'slider',
                gap: 16,
                rewind: false,
                perView: 1,
                classes: {

                    activeNav: 'dot--active',

                }


            }).mount();
        }

        if (gallerySlider) {
            new Glide(gallerySlider, {
                type: 'carousel',
                perView: 1,
                rewind: false,
                gap: 10,


            }).mount();
        }

    }

    // Show Square Room List Title
    showSquareRoomListTitle();
    function showSquareRoomListTitle() {
        let room = document.querySelectorAll('.rooms-item');
        if (room.length > 0) {
            window.addEventListener('scroll', function (e) {
                for (let i = 0; i < room.length; i++) {
                    let title = room[i].querySelector('.room-title');
                    if (inWindow(room[i])) {

                        title.classList.add('rooms-item-title--has-square');

                    }

                }
            });
        }


    }

    // Init Lightbox 
    var lightbox = GLightbox();





    // Input Animation
    inputAnimation();
    function inputAnimation() {
        let field = document.querySelectorAll('.form-field');

        field.forEach(el => {
            let input = el.querySelector('.input');
            let label = el.querySelector('.label');
            input.addEventListener('input', () => {
                el.classList.add('form-field--active')

            })
            input.addEventListener('focus', () => {

                el.classList.add('form-field--active')

            })
            input.addEventListener('focusout', () => {
                console.log(0);

                if (!input.value) {

                    el.classList.remove('form-field--active')
                }



            })

        })
    }


    // Faq
    faq();
    function faq() {
        let faqItem = document.querySelectorAll('.faq-item');

        faqItem.forEach((item) => {
            let title = item.querySelector('.faq-title');
            title.addEventListener('click', function () {
                item.classList.toggle('faq-item--active')
            })

        })

    }




})();