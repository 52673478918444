(function () {

    window.addEventListener('load', function () {
        const images = document.querySelectorAll('.image-animation');

        images.forEach(image => {
            const wrap = image.querySelector('.image-animation-wrap');
            const color = wrap.querySelector('.image-animation-color')

            if (image.dataset.delay) wrap.style.transitionDelay = image.dataset.delay + 's'

            wrap.addEventListener('transitionend', function () {
                wrap.style.transitionDelay = ''
                color.classList.add('image-animation-color--hidden')
                image.classList.add('image-animation--image-normal')
            })

            if (image.inView()) {
                image.classList.add('image-animation--run');
                image.classList.add('image-animation--grow');
            }

            window.addEventListener('scroll', function () {
                if (image.inView()) image.classList.add('image-animation--run');
            })
        });
    })
})()