(function () {
    window.addEventListener('load', function () {
        const allText = document.querySelectorAll('.text-animation')

        allText.forEach(text => {

            if (text.inView()) text.classList.add('text-animation--run');
            if (text.dataset.delay) text.style.transitionDelay = text.dataset.delay + 's';

            window.addEventListener('scroll', function () {
                if (text.inView()) text.classList.add('text-animation--run');
                if (text.dataset.delay) text.style.transitionDelay = text.dataset.delay + 's';
            })
        });


        const textsScrollable = document.querySelectorAll('.giant-text-scrollable');

        textsScrollable.forEach(text => {

        });
    })

})()