(function () {
    window.addEventListener('load', function () {

        const carousels = document.querySelectorAll('.carousel');



        carousels.forEach(carousel => {
            const container = carousel.querySelector('.carousel-items');
            const items = carousel.querySelectorAll('.carousel-item');
            const nextButton = carousel.querySelector('.js-next');
            const prevButton = carousel.querySelector('.js-prev');
            const itemMarginRight = window.getComputedStyle(items[0]).marginRight.replace('px', '')
            let isDown, startX, currentX, change, counter = 0;
            const dots = document.querySelectorAll('.carousel-dot');


            let n = 0;
            nextButton.addEventListener('click', function () {
                if (n < (items.length - 1)) {
                    dots[n].classList.remove('carousel-dot--current');
                    n++
                    dots[n].classList.add('carousel-dot--current');
                    prevButton.classList.remove('is-disabled')
                    container.style.transform = 'translateX(-' + ((+itemMarginRight + items[n].children[0].offsetWidth) * n) + 'px)'
                    items[n].children[0].classList.remove('is-next')
                    items[n - 1].children[0].classList.add('is-prev')

                    if (n === (items.length - 1)) {
                        nextButton.classList.add('is-disabled')
                    }

                }

            })
            prevButton.addEventListener('click', function () {
                if (n > 0) {
                    dots[n].classList.remove('carousel-dot--current');
                    n--
                    dots[n].classList.add('carousel-dot--current');
                    nextButton.classList.remove('is-disabled')
                    container.style.transform = 'translateX(-' + ((+itemMarginRight + items[n].children[0].offsetWidth) * n) + 'px)'
                    items[n].children[0].classList.remove('is-prev')
                    items[n + 1].children[0].classList.add('is-next')
                    if (n === 0) {
                        prevButton.classList.add('is-disabled')
                    }
                }

            })


            swipeCarousel()

            function swipeCarousel() {
                container.addEventListener('touchstart', function (e) {
                    isDown = true;
                    startX = parseInt(e.touches[0].clientX);
                    currentX = getTranslateX(container);
                })

                container.addEventListener('touchmove', function (e) {
                    const touch = e.touches[0];
                    change = startX - touch.clientX;

                    if (isDown) {
                        if (-(- parseInt(currentX) + change) > (- (items[0].offsetWidth) * (items.length - 1)) - (itemMarginRight * (items.length - 1))) {
                            container.style.transform = 'translateX(-' + (- parseInt(currentX) + change) + 'px)';
                        } else {
                            console.log('end');
                        }
                    }

                })
                container.addEventListener('touchend', function (e) {
                    let half = screen.width / 5;


                    if (Math.abs(change) > half) {

                        if (Math.sign(change) == -1) {
                            if (counter < 0) counter++;


                        } else {
                            if (counter > (- items.length) + 1) counter--;


                        }

                        if (Math.abs(change) > 0) {

                            if (Math.sign(change) == -1) {
                                if (n > 0) {
                                    dots[n].classList.remove('carousel-dot--current');
                                    n--
                                    dots[n].classList.add('carousel-dot--current');
                                }

                            } else {
                                if (n < (items.length - 1)) {

                                    dots[n].classList.remove('carousel-dot--current');
                                    n++
                                    dots[n].classList.add('carousel-dot--current');
                                }
                            }

                            container.style.transform = 'translateX(' + (items[0].offsetWidth * counter) + 'px)';
                            container.style.transition = 'transform ' + 500 + 'ms ease';
                            setTimeout(() => {
                                container.style.transition = '';
                            }, 500);
                        }

                    } else {

                        if (Math.abs(change) > 0) {

                            container.style.transform = 'translateX(' + (items[0].offsetWidth * counter) + 'px)';
                            container.style.transition = 'transform ' + 500 + 'ms ease';
                            setTimeout(() => {
                                container.style.transition = '';
                            }, 500);
                        }

                    }

                    isDown = false;
                    change = 0;
                })

            }
        });
    })
    function matrixToArray(str) {
        return str.match(/(-?[0-9\.]+)/g);
    }

    function getTranslateX(element) {
        let styles = window.getComputedStyle(element);
        matrix = styles.getPropertyValue('transform');
        matrix = matrixToArray(matrix);

        if (matrix) {
            return matrix[4];
        } else {
            return 0;
        }
    }
    function isDesktop() {
        if (window.innerWidth >= 992) {
            return true;
        } else {
            return false;
        }
    };

    function isMobile() {
        if (window.innerWidth <= 767) {
            return true;
        } else {
            return false;
        }
    };
})()