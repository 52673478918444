(function () {
    window.addEventListener('load', function () {
        const elements = document.querySelectorAll('.js-text-splitted')

        elements.forEach(element => {
            let text = element.textContent;
            let splitted = text.split(' ');
            let counter = 1;
            let textElement = document.createElement('div')
            let temp = 0;



            splitted.forEach(word => {
                let wordElement = document.createElement('div');
                let wordElementWrap = document.createElement('span');
                wordElement.classList.add('word');
                wordElementWrap.textContent = word + '\xa0';
                wordElementWrap.style.transitionDelay = '.' + counter + 5 + 's'
                wordElement.append(wordElementWrap)
                textElement.append(wordElement)

                if (counter <= splitted.length) counter++

            });


            textElement.classList.add('text-splitted');

            if (element.inView()) {
                setTimeout(() => {
                    textElement.classList.add('text-splitted--active')
                }, 1000);
            }




            window.addEventListener('scroll', function () {
                if (element.inView()) {
                    textElement.classList.add('text-splitted--active');
                }
            })



            element.textContent = '';
            element.append(textElement)


        });
    })

})();