(function () {

    const burger = document.getElementById('burger-button');


    burger.addEventListener('click', function () {
        this.classList.toggle('burger--active')
        showMobileMenu();
    })

    function showMobileMenu() {
        const menu = document.querySelector('.site-menu-mobile');
        const links = menu.querySelectorAll('.menu-link');

        if (!menu.classList.contains('site-menu-mobile--active')) {
            let counter = links.length;
            menu.classList.add('site-menu-mobile--active');
            links.forEach(link => {
                link.style.transitionDelay = '.' + counter + 's';
                counter--
            });
        } else {
            menu.classList.remove('site-menu-mobile--active');
            links.forEach(link => {
                link.style.transitionDelay = '0s';
            });

        }
    }
})();