(function () {
    window.addEventListener('load', function () {
        const blocks = document.querySelectorAll('.text-image-with-backgorund');

        blocks.forEach(block => {
            const bg = block.querySelector('.block-background');
            if (bg.inView()) bg.classList.remove('block-background-compressed');
            window.addEventListener('scroll', function () {
                if (bg.inView()) bg.classList.remove('block-background-compressed');
            })
        });
    })

})()