(function () {
    window.addEventListener('load', function () {
        const linksSelect = document.querySelectorAll('.link-select');


        linksSelect.forEach(linkSelect => {
            const links = linkSelect.querySelectorAll('.link');
            const link = linkSelect.querySelector('.link');
            const linksList = linkSelect.querySelector('.links')

            linkSelect.addEventListener('click', function (e) {
                e.stopPropagation();
                this.classList.toggle('link-select--active')
                if (this.classList.contains('link-select--active')) {

                    linksList.style.height = link.offsetHeight * links.length + 'px';

                } else {

                    linksList.style.height = 1 + 'px';

                }

            })
        });

        window.addEventListener('click', function () {
            linksSelect.forEach(linkSelect => {
                const linksList = linkSelect.querySelector('.links')

                if (linkSelect.classList.contains('link-select--active')) {
                    linkSelect.classList.remove('link-select--active');
                    linksList.style.height = 1 + 'px';
                }
            });

        })

        const buttons = document.querySelectorAll('.button');

        buttons.forEach(button => {

            const text = button.innerText;
            const borders = document.createElement('div');
            const borderTop = document.createElement('div');
            const borderRight = document.createElement('div');
            const borderBottom = document.createElement('div');
            const borderLeft = document.createElement('div');
            const buttonTextTop = document.createElement('div');
            const buttonTextBottom = document.createElement('div');

            borders.classList.add('button-borders');
            borderTop.classList.add('button-border', 'button-border--top');
            borderRight.classList.add('button-border', 'button-border--right');
            borderBottom.classList.add('button-border', 'button-border--bottom');
            borderLeft.classList.add('button-border', 'button-border--left');

            borders.append(borderTop);
            borders.append(borderRight);
            borders.append(borderBottom);
            borders.append(borderLeft);

            buttonTextTop.classList.add('button-text', 'button-text--top');
            buttonTextBottom.classList.add('button-text', 'button-text--bottom');

            buttonTextTop.textContent = text;
            buttonTextBottom.textContent = text;

            button.textContent = '';
            button.append(borders);
            button.append(buttonTextTop);
            button.append(buttonTextBottom);



        })


        const buttonsAnimate = document.querySelectorAll('.button-animate');

        buttonsAnimate.forEach(button => {

            if (button.inView()) {
                if (button.dataset.delay) {
                    button.style.transitionDelay = button.dataset.delay + 's'
                }
                button.classList.add('button-animate--run')
            }

            button.addEventListener('transitionend', function () {
                button.style.transitionDelay = ''
            })

            window.addEventListener('scroll', function () {
                if (button.inView()) {
                    if (button.dataset.delay) {
                        button.style.transitionDelay = button.dataset.delay + 's'
                    }
                    button.classList.add('button-animate--run')
                }

                button.addEventListener('transitionend', function () {
                    button.style.transitionDelay = ''
                })
            })
        });
    })


})();