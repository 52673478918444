(function () {
    HTMLElement.prototype.inView = function () {

        const el = this.getBoundingClientRect();

        if (window.pageYOffset > (el.top - window.innerHeight) && window.pageYOffset < (el.top + this.offsetHeight)) {
            return true
        } else {
            return false;
        }
    };
})();